<template>
  <div class="SpreadMgrList">
    <list
      exportPermission="export"
      :searchUrl="searchUrl"
      :exportUrl="exportUrl"
      :searchParams.sync="searchParams"
      :headers="headers"
    >
      <template #headerSlot>
        <v-button text="推广配置" permission="add" @click="create" />
      </template>
      <template #searchSlot>
        <v-input label="项目名称" v-model="searchParams.communityName" />
        <v-select label="推广标签" v-model="searchParams.promotionType" :options="showTypeOps" />
        <v-input label="所在省" v-model="searchParams.province" />
        <v-input label="所在市" v-model="searchParams.city" />
        <v-input label="所在区" v-model="searchParams.area" />
        <v-select label="应用类型" v-model="searchParams.dataLevel" :options="subjectTypeOps" />
        <v-select2 label="包含应用" placeholder="查询应用" v-model="searchParams.subId" v-bind="subjectParams" :subjoin="subjectSubjoin" :disabled="subjectDisabled" />
        <v-datepicker label="配置时间" :maxDate="maxDate" type="rangedatetimer" :startTime.sync="searchParams.setupTimeFrom" :endTime.sync="searchParams.setupTimeTo" />
        <v-datepicker label="更新时间" :maxDate="maxDate" type="rangedatetimer" :startTime.sync="searchParams.updateTimeFrom" :endTime.sync="searchParams.updateTimeTo" />
        <v-select2 label="最后操作人" placeholder="查询操作人" v-model="searchParams.updateUser" v-bind="updateUserParams" />
      </template>
      <template #operateSlot="scope">
        <v-button text="编辑" permission="update" type="text" @click="edit(scope.row)" />
      </template>
    </list>
  </div>
</template>

<script>
import { getListURL, exportListURL, getSubjectListURL, getUserListURL } from './api'
import { showTypeMap, setShowTypeOps, setSubjectTypeOps } from './map'

export default {
  name: 'SpreadMgrList',
  data () {
    return {
      maxDate: new Date(),
      searchUrl: getListURL,
      exportUrl: exportListURL,
      showTypeOps: setShowTypeOps(1),
      subjectTypeOps: setSubjectTypeOps(1),
      subjectParams: {
        searchUrl: getSubjectListURL,
        request: {
          text: 'subName',
          value: 'id'
        },
        response: {
          text: 'name',
          value: 'id'
        }
      },
      updateUserParams: {
        searchUrl: getUserListURL,
        request: {
          text: 'keyword',
          value: 'id'
        },
        response: {
          text: 'username',
          value: 'id'
        }
      },
      searchParams: {
        communityName: '',
        promotionType: undefined,
        province: '',
        city: '',
        area: '',
        dataLevel: undefined,
        subId: '',
        setupTimeFrom: '',
        setupTimeTo: '',
        updateTimeFrom: '',
        updateTimeTo: '',
        updateUser: ''
      },
      headers: [
        {
          prop: 'communityName',
          label: '项目名称'
        },
        {
          prop: 'promotionTypeText',
          label: '推广标签',
          formatter (row) {
            return showTypeMap[row.promotionType]
          }
        },
        {
          prop: 'province',
          label: '所在省'
        },
        {
          prop: 'city',
          label: '所在市'
        },
        {
          prop: 'area',
          label: '所在区'
        },
        {
          prop: 'subjectsText',
          label: '配置应用',
          formatter (row) {
            return row.subjects.join('、')
          }
        },
        {
          prop: 'intime',
          label: '配置时间'
        },
        {
          prop: 'updateTime',
          label: '更新时间'
        },
        {
          prop: 'updateUserName',
          label: '最后操作人'
        }
      ]
    }
  },
  computed: {
    subjectSubjoin () {
      return {
        dataLevel: this.searchParams.dataLevel
      }
    },
    subjectDisabled () {
      return this.searchParams.dataLevel === undefined
    }
  },
  methods: {
    create () {
      this.$router.push({
        name: 'spreadMgrForm'
      })
    },
    edit (row) {
      this.$router.push({
        name: 'spreadMgrForm',
        query: {
          communityId: row.communityId,
          communityName: row.communityName,
          promotionType: row.promotionType
        }
      })
    }
  }
}
</script>
